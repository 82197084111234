// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-classes-js": () => import("./../../../src/pages/classes.js" /* webpackChunkName: "component---src-pages-classes-js" */),
  "component---src-pages-contact-hire-js": () => import("./../../../src/pages/contact-hire.js" /* webpackChunkName: "component---src-pages-contact-hire-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-hiring-js": () => import("./../../../src/pages/hiring.js" /* webpackChunkName: "component---src-pages-hiring-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-location-js": () => import("./../../../src/pages/location.js" /* webpackChunkName: "component---src-pages-location-js" */),
  "component---src-pages-page-2-tsx": () => import("./../../../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-pages-schedule-js": () => import("./../../../src/pages/schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */),
  "component---src-templates-class-js": () => import("./../../../src/templates/class.js" /* webpackChunkName: "component---src-templates-class-js" */),
  "component---src-templates-program-js": () => import("./../../../src/templates/program.js" /* webpackChunkName: "component---src-templates-program-js" */)
}

